@font-face {
  font-family: 'Genome';
  src: local('Genome-Regular'),
    url('assets/fonts/Genome-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Genome';
  font-weight: 700;
  src: local('Genome-Bold'),
    url('assets/fonts/Genome-Bold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Genome';
  font-weight: 600;
  src: local('Genome-Demi-Bold'),
    url('assets/fonts/Genome-Demi-Bold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Genome';
  font-weight: 200;
  src: local('Genome-Light'),
    url('assets/fonts/Genome-Light.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Genome';
  font-weight: 300;
  src: local('Genome-Light-Oblique'),
    url('assets/fonts/Genome-Light-Oblique.otf') format('opentype');
  font-display: swap;
}

.App {
  background-color: #ffffff;
  font-family: Genome, serif;
  transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease;
}
.viewHeightContainer {
  min-height: calc(100vh - 150px);
}
